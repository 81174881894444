import React, { useEffect, useState,useRef } from 'react'
import { toast } from 'react-toastify';
import { Button, Form, RadioButton, SelectBox } from '../../primary'
import editIcon from '../../../static/images/edit-icon.png'
import { woundClassificationAsync, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride,getclassificationAsync,ne1updateassessmentclassificationAsync } from '../../../app/patientSlice'
import { CloseWarning } from '../../secondary'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { allNullCheck, signedLockedAccess, ServiceError } from '../../../utils'
import { enableCheckerMove, handleFirstMove } from '../../../utils/productsHelper'
import infoIcon from '../../../static/images/info.svg';

/* Import PI Images */
import classificationPiClosed1 from '../../../static/images/classification_PI_Images/closed/1.jpg'
import classificationPiClosed2 from '../../../static/images/classification_PI_Images/closed/2.jpg'
import classificationPiClosed3 from '../../../static/images/classification_PI_Images/closed/3.jpg'
import classificationPiPrestage1 from '../../../static/images/classification_PI_Images/prestage1/1.jpg'
import classificationPiPrestage2 from '../../../static/images/classification_PI_Images/prestage1/2.jpg'
import classificationPiPrestage3 from '../../../static/images/classification_PI_Images/prestage1/3.jpg'
import classificationPiStageFirst1 from '../../../static/images/classification_PI_Images/stage1/1.jpg'
import classificationPiStageFirst2 from '../../../static/images/classification_PI_Images/stage1/2.jpg'
import classificationPiStageFirst3 from '../../../static/images/classification_PI_Images/stage1/3.jpg'
import classificationPiStageSecond1 from '../../../static/images/classification_PI_Images/stage2/1.jpg'
import classificationPiStageSecond2 from '../../../static/images/classification_PI_Images/stage2/2.jpg'
import classificationPiStageSecond3 from '../../../static/images/classification_PI_Images/stage2/3.jpg'
import classificationPiStageThird1 from '../../../static/images/classification_PI_Images/stage3/1.jpg'
import classificationPiStageThird2 from '../../../static/images/classification_PI_Images/stage3/2.jpg'
import classificationPiStageThird3 from '../../../static/images/classification_PI_Images/stage3/3.jpg'
import classificationPiStageFourth1 from '../../../static/images/classification_PI_Images/stage4/1.jpg'
import classificationPiStageFourth2 from '../../../static/images/classification_PI_Images/stage4/2.jpg'
import classificationPiStageFourth3 from '../../../static/images/classification_PI_Images/stage4/3.jpg'
import classificationPiDtpi1 from '../../../static/images/classification_PI_Images/dtpi/1.jpg'
import classificationPiDtpi2 from '../../../static/images/classification_PI_Images/dtpi/2.jpg'
import classificationPiDtpi3 from '../../../static/images/classification_PI_Images/dtpi/3.jpg'
import classificationPiUnstageable1 from '../../../static/images/classification_PI_Images/unsatgeable/1.jpg'
import classificationPiUnstageable2 from '../../../static/images/classification_PI_Images/unsatgeable/2.jpg'
import classificationPiUnstageable3 from '../../../static/images/classification_PI_Images/unsatgeable/3.jpg'
import classificationPiMmpi1 from '../../../static/images/classification_PI_Images/mmpi/1.jpg'
import classificationPiMmpi2 from '../../../static/images/classification_PI_Images/mmpi/2.jpg'
import classificationPiMmpi3 from '../../../static/images/classification_PI_Images/mmpi/3.jpg'

/* Import Other Images */
import classiOtherClosed1 from '../../../static/images/classification_Other_Images/Closed/1.jpg'
import classiOtherClosed2 from '../../../static/images/classification_Other_Images/Closed/2.jpg'
import classiOtherClosed3 from '../../../static/images/classification_Other_Images/Closed/3.jpg'
import classiOtherClosed4 from '../../../static/images/classification_Other_Images/Closed/4.jpg'
import classiOtherClosed5 from '../../../static/images/classification_Other_Images/Closed/5.jpg'
import classiOtherClosed6 from '../../../static/images/classification_Other_Images/Closed/6.jpg'
import classiOtherSuperFicial1 from '../../../static/images/classification_Other_Images/Superficial/1.jpg'
import classiOtherSuperFicial2 from '../../../static/images/classification_Other_Images/Superficial/2.jpg'
import classiOtherSuperFicial3 from '../../../static/images/classification_Other_Images/Superficial/3.jpg'
import classiOtherSuperFicial4 from '../../../static/images/classification_Other_Images/Superficial/4.jpg'
import classiOtherSuperFicial5 from '../../../static/images/classification_Other_Images/Superficial/5.jpg'
import classiOtherSuperFicial6 from '../../../static/images/classification_Other_Images/Superficial/6.jpg'
import classiOtherParitcalThick1 from '../../../static/images/classification_Other_Images/ParticalThickness/1.jpg'
import classiOtherParitcalThick2 from '../../../static/images/classification_Other_Images/ParticalThickness/2.jpg'
import classiOtherParitcalThick3 from '../../../static/images/classification_Other_Images/ParticalThickness/3.jpg'
import classiOtherParitcalThick4 from '../../../static/images/classification_Other_Images/ParticalThickness/4.jpg'
import classiOtherParitcalThick5 from '../../../static/images/classification_Other_Images/ParticalThickness/5.jpg'
import classiOtherParitcalThick6 from '../../../static/images/classification_Other_Images/ParticalThickness/6.jpg'
import classiOtherFullThick1 from '../../../static/images/classification_Other_Images/FullThickness/1.jpg'
import classiOtherFullThick2 from '../../../static/images/classification_Other_Images/FullThickness/2.jpg'
import classiOtherFullThick3 from '../../../static/images/classification_Other_Images/FullThickness/3.jpg'
import classiOtherFullThick4 from '../../../static/images/classification_Other_Images/FullThickness/4.jpg'
import classiOtherFullThick5 from '../../../static/images/classification_Other_Images/FullThickness/5.jpg'
import classiOtherFullThick6 from '../../../static/images/classification_Other_Images/FullThickness/6.jpg'

import { Confirmation } from '../../secondary/Confirmation/confirmation'   
import warning from '../../../static/images/icon-check-danger.png';

export function CharacteristicClassification({ index, updateDone, patientId, assessmentId, dispatch, selectedIndex, assessmentDetails,
    getCharDetails, styles, hideIt, overrideToggle,isCompleted, characteristicTabsData }) {
     // Edit state
  const [editable, setEditable] = useState(false)
  const [formChangesConfirm, setFormChangesConfirm] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [clickCancel, setClickCancel] = useState(false)
  const confirmationClose = () => {
      setConfirmationModal(false);
      setClickCancel(false)
  }
    const yesConfirmationModalClick = () => {
      setConfirmationModal(false)
      setEditable(false)
      setFormChangesConfirm(false)
      setClickCancel(false)
    }
    useEffect(() => {
        if(editable){
            if(formChangesConfirm&&clickCancel){
                setConfirmationModal(true);
              }else if(clickCancel&&formChangesConfirm===false){
                setClickCancel(false)
                setConfirmationModal(false);
                setEditable(false)
              }
        }
    }, [formChangesConfirm,clickCancel,editable])


        /* Hook form */
     // For default values
     let defaultTemp = isCompleted===1?{...assessmentDetailsNew?.classification}:{}
     defaultTemp['woundBedIdentifiable'] = defaultTemp?.woundBedIdentifiable === 1 ? 'Yes' : defaultTemp?.woundBedIdentifiable === 0 ? 'No' : null
 
  
   const [assessmentDetailsNew, setAssessmentDetailsNew] = useState('');
   const { register, errors, handleSubmit, setError, reset:reset, getValues, watch,setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: isCompleted===1&&assessmentDetailsNew?.classification ? defaultTemp : {}
})

    //call the API for Update the data
    useEffect(()=>{
        reset({})
        toast.dismiss('success1')
        let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        dispatch(patientAssessmentAsyncOverride(payload))  
        dispatch(getclassificationAsync(payload))  
    },[dispatch])

const classificationData = useSelector((state) => state.patient.classificationDataByAssessID);
const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
      /* get user role*/ 
      const getUserRole = useSelector(state => state.user);

useEffect(() => {
    if(classificationData){
        
        setAssessmentDetailsNew({'classification':classificationData?.classifications})
        if(isCompleted===0){
            setAssessmentDetailsNew('')
            setEditable(true)
            // if (classificationData?.classifications === null) {
            //     dispatcher(null, 1)
            // }
            // if (classificationData?.classifications !== null) {
            //     dispatcher(classificationData?.classifications?.woundBedIdentifiable, 0)
            // }
        }
    }

}, [classificationData,isCompleted])

    useEffect(() => {
        if (assessmentDetails?.classification === null) {
            dispatcher(null, 1)
        }
        if (assessmentDetails?.classification !== null) {
            dispatcher(assessmentDetails?.classification?.woundBedIdentifiable, 0)
        }
    }, [assessmentDetails])
  

    const ErrorState = ServiceError(); // without TT selected if goes to Classification show error message

  
    const [closeWarning, setCloseWarning] = useState(false) // Close warning
    const [displayFirst, setDisplayFirst] = useState(false) // State for displaying first field
    const [enabled, setEnabled] = useState(false)  // If dropdown has enabled value, need that to be selected
    const [noTissueTypes, setNoTissueTypes] = useState(false) // If tissue types is not selected, display message on submit

    const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options
   
    // If the API get call has classification data, then show 'view' screen else show edit screen directly
    useEffect(() => {
        if(signedLockedAccess(assessmentDetails?.assessmentStage)){
            if (assessmentDetailsNew?.classification?.woundBedIdentifiable===null&&assessmentDetailsNew?.classification?.woundClassification === 'Unknown') {
                setEditable(true)
                setTimeout(() => {
                    // if(document.getElementById('woundClassification')){
                    //     document.getElementById('woundClassification').value=''
                    // }
                    if(getValue){  // New UI Classification
                        setGetValue('')
                    }
                }, 100)
            } 
            if (assessmentDetailsNew?.classification?.woundBedIdentifiable===null&&assessmentDetailsNew?.classification?.woundClassification === 'unknown') {
                setEditable(true)
                setTimeout(() => {
                    // if(document.getElementById('woundClassification')){
                    //     document.getElementById('woundClassification').value=''
                    // }
                    if(getValue){  // New UI Classification
                        setGetValue('')
                    }
                }, 100)
            } 
        }
       
    }, [assessmentDetailsNew])

    // Is selected tissue type is follwing, only then display the "can the wound bed be identified"
    // const selectedCondition = [1, 2, 3, 4, 5, 6, 7, 8]
    const selectedCondition = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]   // New UI Classification
    useEffect(() => {
        // If the selected tissue type belongs to above code, set the wound bed field state to true
        assessmentDetails?.tissueTypes?.map((data) => { //NOSONAR
            if (selectedCondition.includes(data.id)) {
                setDisplayFirst(true)
            }
        })//NOSONAR
    }, [])


    //Wound classification dropdown while override toggle click it will enable all options..
    const [toggleOn, setToggleOn] = useState(false)
    const toggleChange =() => {
        if(toggleOn === false){
            setToggleOn(true)
        }else{
            setToggleOn(false)
        }
    }

    /* Warning functions Yes and no */
    const handleYes = () => {
        //    reset({});
        setCloseWarning(false)
        setEditable(false)
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }

   
    //const dispatcher = (identifiable, classifiable, config) => {        
    const dispatcher = (identifiable, config) => {
        // When woundbed is selected as 'no' we still need to display dropdown for 'wound classification'
        const payload = {
            patientId,
            assessmentId,
            data: {
                woundBedIdentifiable: identifiable,
                configurationRequest: config
            },
            overrideenable : toggleOn === true ? true : false            
        }        
        dispatch(woundClassificationAsync(payload))
    }

    // First dispatch for new classification and edit mode
    // useEffect(() => {
    //     if (assessmentDetailsNew?.classification === null) {
    //         dispatcher(null, 1)
    //     }
    //     if (assessmentDetailsNew?.classification !== null) {
    //         dispatcher(assessmentDetailsNew?.classification?.woundBedIdentifiable, 0)
    //     }
    // }, [assessmentDetailsNew])

    const [showState, setShowState] = useState(false) // New UI Classification

    // Handle first field
    const handleFirst = (e) => {
        if(e.target.value !== ''){ // New UI Classification
            setShowState(true)
        }
        handleFirstMove(e.target.value, reset, getValues, dispatcher)
    }

    // This below function check wheater dropdown value is selected if atlest one is enabled.
    function enabledChecker() {
        enableCheckerMove(woundClassificationOptions?.result, setEnabled)
    }

    // Run the above function everytime the wound classification option dropdown value changes
    useEffect(() => {
        enabledChecker()
    }, [woundClassificationOptions?.result])

    // Classification no/non checker
    const selectedClassification = watch(['woundClassification'])


    useEffect(() => {
        // If selected option is 'nonremovable' then disable all fields.
        if (selectedClassification?.woundClassification === 'nonremovabledressingdevice') {
            if (document.getElementById('woundClassification')) {
                if (document.getElementById('woundClassification').getElementsByTagName('option')) {
                    const selectItems = document.getElementById('woundClassification').getElementsByTagName('option')
                    for (let i = 0; i < selectItems.length; i++) {
                        // Disable all items except non 'removable dressing'
                        if (selectItems[i].value.toLowerCase() !== "nonremovabledressingdevice" && selectItems[i].value.toLowerCase() !== "") {
                            selectItems[i].disabled = true
                        } else {
                            selectItems[i].disabled = false
                        }
                    }
                }
            }
        }
        // Else enable based on API
        if (selectedClassification?.woundClassification !== 'nonremovabledressingdevice') {
            if (document.getElementById('woundClassification')) {
                if (document.getElementById('woundClassification').getElementsByTagName('option')) {
                    const selectItems = document.getElementById('woundClassification').getElementsByTagName('option')
                    for (let i = 0; i < selectItems.length; i++) {
                        // Disable all items except non 'removable dressing'
                        if (woundClassificationOptions?.result && woundClassificationOptions?.result?.length > 0) {
                            if (woundClassificationOptions?.result[i - 1]?.enable === 1) {
                                selectItems[i].disabled = false;
                            }
                            else if (woundClassificationOptions?.result[i - 1]?.enable === 0) {
                                // selectItems[i].disabled = false
                                if(toggleOn === false){ // added for override option
                                    selectItems[i].disabled = true
                                }
                                if(toggleOn === true){
                                    selectItems[i].disabled = false  
                                } //                             
                            }
                        }
                    }
                }
            }
        }

    }, [selectedClassification, woundClassificationOptions?.result, toggleOn])

   
    // Display no selected error for woundClassification
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // if (enabled && getValues('woundClassification') === '') {
            //     setError('woundClassification', {
            //         type: "manual",
            //         message: "Please select wound classification"
            //     })
            // }
            // if (enabled && (getValues(getValue) === undefined || getValues(getValue) === '')) { // New UI Classification
            //     setError('woundClassification', {
            //         type: "manual",
            //         message: "Please select wound stage"
            //     })
            // }
        }
    }, [errors])
    const charStatus = useSelector(state => state.patient.charStatus)

    const [errorShowing, setErrorShowing] = useState(false)


    const onSubmit = (data) => {
        if (assessmentDetails?.tissueTypes === null) {
            setNoTissueTypes(true)
            setTimeout(() => {
                setNoTissueTypes(false)
            }, 5000)
            return;
        }
        // if (enabled && data.woundClassification === '') {
        if(enabled && (getValue === undefined || getValue === '')){ // New UI Classification
            // If wound classificaion is not selected set error
            // setError('woundClassification', { type: 'Manual', message: 'Please select wound stage' })
            setErrorShowing(true)
        }
        else {
            setErrorShowing(false)
            const navigate = () => {
                updateDone(index)
                if(Array.isArray(charStatus)&&charStatus[index]===1){
                    setEditable(false)
                }else{
                    selectedIndex(index + 1) /* user will get navigated to next tab */
                }
            }
            // Temp assessment with submitted data
            const tempAssessment = {
                classification: {
                    woundBedIdentifiable: data.woundBedIdentifiable ? data.woundBedIdentifiable === 'Yes' ? 1 : 0 : null,
                    // woundClassification: data.woundClassification ? data.woundClassification : null
                    woundClassification: getValue ? getValue : null  // New UI Classification
                }
            }
            // Merging our temp assessment with assessment details and dispatching
            const assessment = { ...assessmentDetails, ...tempAssessment }
            const payload = {
                patientId,
                assessmentId,
                assessment,
                navigate,
                getCharDetails,
                overrideenable : overrideToggle ? 1 : 0
            }
            dispatch(ne1updateassessmentclassificationAsync(payload))
            //dispatch(woundAssessmentUpdateAsync(payload))
            //ne1updateassessmentclassificationAsync
            
        }
        
    }



    //added for New UI Classification

        const [hovercard, setCardhover] = useState(false)
        const [selectOne, setSelectOne] = useState(false)
        const [selectTwo, setSelectTwo] = useState(false)
        const [selectThree, setSelectThree] = useState(false)
        const [selectFour, setSelectFour] = useState(false)
        const [noBackground, setNoBackground] = useState(false)
        const [keyValue, setKeyValue] = useState()
        const [getValue, setGetValue] = useState();
        const [counter, setCounter] = useState(0);
        const [woState, setWoState] = useState(assessmentDetailsNew?.classification?.woundClassification)
        const prevCount = useRef();
        const [makeCounter, setMakeCounter] = useState(false)

        useEffect(() => {
            prevCount.current = getValue;
        }, [getValue]); 

       const handleCategoryChange = (eve, data, index) => {
            let val = data.key
            setKeyValue(val)

            setCounter(counter+1)
            let counterDivide = (counter % 2===0 ? true : false);
            setMakeCounter(counter % 2===0 ? true : false)

            if(selectOne === false){
                setSelectOne(true)
                setSelectTwo(false)
                setSelectThree(false)
                setSelectFour(false)
                setGetValue(data.key)
                setNoBackground(false)
            }
            
            
            //double click should be unselect
            if((selectOne===true&&keyValue===prevCount.current)){
                setGetValue("")
                setNoBackground(true)
            }
            
            if(selectOne===true&&keyValue===assessmentDetailsNew?.classification?.woundClassification&&noBackground===true){
                setGetValue(data.key)
                setNoBackground(false)
                setSelectTwo(false)
                setSelectThree(false)
                setSelectFour(false)
            }

            if(selectOne===true&&!keyValue&&noBackground===true){
                setGetValue(data.key)
                setNoBackground(false)
                setSelectTwo(false)
                setSelectThree(false)
                setSelectFour(false)
            }
       }
       
       const handleRadioChangeOne = (e,data,index) => {
            let val = data.key
            setKeyValue(val)

            if(selectTwo === false){
                setSelectTwo(true)
                setSelectOne(false)
                setSelectThree(false)
                setSelectFour(false)
                setGetValue(data.key)
                setMakeCounter(false)
            }
       
       }

       const handleRadioChangeTwo = (e,data,index) => {
            let valtwo = data.key
            setKeyValue(valtwo)
            
            if(selectThree === false){
                setSelectThree(true)
                setSelectFour(false)
                setSelectTwo(false)
                setSelectOne(false)
                setGetValue(data.key)
                setMakeCounter(false)
            } 

       }

       const handleRadioChangeThree = (e,data,index) => {
        let valthree = data.key
        setKeyValue(valthree)
        
        if(selectFour === false){
            setSelectFour(true)
            setSelectOne(false)
            setSelectTwo(false)
            setSelectThree(false)
            setGetValue(data.key)
            setMakeCounter(false)
        } 

   }

   useEffect(()=>{
        if(makeCounter===true && selectOne===true && (woundClassificationOptions?.result?.map(item=>item===keyValue)) && prevCount.current === ''){
            setNoBackground(false)
            setGetValue(keyValue)
            setSelectTwo(false)
            setSelectThree(false)
            setSelectFour(false)
        }
   },[makeCounter])

    useEffect(()=>{  
        // if(assessmentDetails?.historyCause?.causeCategory === 'pi'){  
        //     if(selectTwo===false && assessmentDetailsNew?.classification?.woundClassification === "nonremovabledressingdevice"){
        //         setSelectTwo(true)
        //     }
        //     if(selectThree===false && assessmentDetailsNew?.classification?.woundClassification === "unabletodeterminewoundclassification"){
        //         setSelectThree(true)
        //         setSelectTwo(false)            
        //     }
        // }
        // if(assessmentDetails?.historyCause?.causeCategory === 'other'){
        //     if(selectTwo===false && assessmentDetailsNew?.classification?.woundClassification === "nonremovabledressingdevice"){
        //         console.log('comingsecond');
        //         setSelectTwo(true)
        //     }
        //     if(selectThree===false && assessmentDetailsNew?.classification?.woundClassification === "unabletodeterminewoundclassification"){
        //         console.log('comingsecond3');
        //         setSelectThree(true)
        //         setSelectTwo(false)
        //     }
        // }

        if(selectTwo===false && assessmentDetailsNew?.classification?.woundClassification === "nonremovabledressingdevice"){
            setSelectTwo(true)
        }
        if(selectThree===false && assessmentDetailsNew?.classification?.woundClassification === "unabletodeterminewoundclassification"){
            setSelectThree(true)
            setSelectTwo(false)            
        }
        if(selectFour===false && assessmentDetailsNew?.classification?.woundClassification === "woundcannotbestaged"){
            setSelectFour(true)
            setSelectTwo(false) 
            setSelectThree(false)           
        }
        
    },[assessmentDetailsNew])

    useEffect(()=>{
        if((selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='closed')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='prestage1blanchableerythemia')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='stage1')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='stage2')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='stage3')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='stage4')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='dtpideeptissuepressureinjuryulcermayevolveorresolve')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='unstageable')||
        (selectOne===false&&assessmentDetailsNew?.classification?.woundClassification==='mmpimucosal')){         
            if(selectTwo===true || selectThree=== true || selectFour === true){
                setNoBackground(true)
            }            
        }
    },[selectTwo, selectThree, selectFour,assessmentDetailsNew])

    useEffect(()=>{   
        if((keyValue!==assessmentDetailsNew?.classification?.woundClassification && selectOne===true)){
            setWoState('')
            setGetValue(keyValue)
            setNoBackground(false)
        }              
       
    },[keyValue,assessmentDetailsNew])

    useEffect(()=>{
        if((selectOne===true)&&(assessmentDetailsNew?.classification?.woundClassification==='closed'||assessmentDetailsNew?.classification?.woundClassification==='prestage1blanchableerythemia'||
            assessmentDetailsNew?.classification?.woundClassification==='stage1'||assessmentDetailsNew?.classification?.woundClassification==='stage2'||
            assessmentDetailsNew?.classification?.woundClassification==='stage3'||assessmentDetailsNew?.classification?.woundClassification==='stage4'||
            assessmentDetailsNew?.classification?.woundClassification==='dtpideeptissuepressureinjuryulcermayevolveorresolve'||assessmentDetailsNew?.classification?.woundClassification==='unstageable'||
            assessmentDetailsNew?.classification?.woundClassification==='mmpimucosal'|| assessmentDetailsNew?.classification?.woundClassification==='superficial'|| assessmentDetailsNew?.classification?.woundClassification==='partialthickness'
            || assessmentDetailsNew?.classification?.woundClassification==='fullthickness')){
                if(keyValue===woState){
                    setWoState('')
                    setGetValue('')
                    setNoBackground(true)
                }
        }
    },[keyValue,assessmentDetailsNew])

    
    //added for PI Images
    const PiImage = [
        {
            key : 'closed',
            'referenceImage' : [
                classificationPiClosed1,
                classificationPiClosed2,
                classificationPiClosed3,
            ]
        },
        {
            key : 'prestage1blanchableerythemia',
            'referenceImage' : [
                classificationPiPrestage1,
                classificationPiPrestage2,
                classificationPiPrestage3,
            ]
        },
        {
            key : 'stage1',
            'referenceImage' : [
            
                classificationPiStageFirst1,
                classificationPiStageFirst2,
                classificationPiStageFirst3,
            ]
        },
        {
            key : 'stage2',
            'referenceImage' : [
            
                classificationPiStageSecond1,
                classificationPiStageSecond2,
                classificationPiStageSecond3,
            ]
        },
        {
            key : 'stage3',
            'referenceImage' : [
            
                classificationPiStageThird1,
                classificationPiStageThird2,
                classificationPiStageThird3,
            ]
        },
        {
            key : 'stage4',
            'referenceImage' : [
            
                classificationPiStageFourth1,
                classificationPiStageFourth2,
                classificationPiStageFourth3,
            ]
        },
        {
            key : 'dtpideeptissuepressureinjuryulcermayevolveorresolve',
            'referenceImage' : [
            
                classificationPiDtpi1,
                classificationPiDtpi2,
                classificationPiDtpi3,
            ]
        },
        {
            key : 'unstageable',
            'referenceImage' : [
            
                classificationPiUnstageable1,
                classificationPiUnstageable2,
                classificationPiUnstageable3,
            ]
        },{
            key : 'mmpimucosal',
            'referenceImage' : [
            
                classificationPiMmpi1,
                classificationPiMmpi2,
                classificationPiMmpi3,
            ]
        }
    ]


    //added for Other Images First_3
    const OtherImageFirstRow = [
        {
            key : 'closed',
            'referenceImage' : [
                classiOtherClosed1,
                classiOtherClosed2,
                classiOtherClosed3,
            ]
        },
        {
            key : 'superficial',
            'referenceImage' : [
                classiOtherSuperFicial1,
                classiOtherSuperFicial2,
                classiOtherSuperFicial3,
            ]
        },
        {
            key : 'partialthickness',
            'referenceImage' : [
            
                classiOtherParitcalThick1,
                classiOtherParitcalThick2,
                classiOtherParitcalThick3,
            ]
        },
        {
            key : 'fullthickness',
            'referenceImage' : [
            
                classiOtherFullThick1,
                classiOtherFullThick2,
                classiOtherFullThick3,
            ]
        }            
    ]

    //added for Other Images Another_3
    const OtherImageSecondRow = [
        {
            key : 'closed',
            'referenceImage' : [
                classiOtherClosed4,
                classiOtherClosed5,
                classiOtherClosed6
            ]
        },
        {
            key : 'superficial',
            'referenceImage' : [
                classiOtherSuperFicial4,
                classiOtherSuperFicial5,
                classiOtherSuperFicial6
            ]
        },
        {
            key : 'partialthickness',
            'referenceImage' : [
                classiOtherParitcalThick4,
                classiOtherParitcalThick5,
                classiOtherParitcalThick6
            ]
        },
        {
            key : 'fullthickness',
            'referenceImage' : [                
                classiOtherFullThick4,
                classiOtherFullThick5,
                classiOtherFullThick6
            ]
        }            
    ]
    
    //added for Sub Title
    const subtitlePI = [
        {
            key : 'closed',
            value : ""
        },
        {
            key : 'prestage1blanchableerythemia',
            value : "(Blanchable Skin)"
        },
        {
            key : 'stage1',
            value : "(Non-Blanchable Skin)"
        },
        {
            key : 'stage2',
            value : ""
        },
        {
            key : 'stage3',
            value : ""
        },
        {
            key : 'stage4',
            value : ""
        },
        {
            key : 'dtpideeptissuepressureinjuryulcermayevolveorresolve',
            value : "(Deep Tissue Pressure Injury)"
        },
        {
            key : 'unstageable',
            value : ""
        },
        {
            key : 'mmpimucosal',
            value : "(Mucosal Membrane Pressure Injury)"
        }

    ]
         
    const onClickAvailabilityOne = (e) =>{
        if(e.target.checked && !selectTwo){
            setSelectTwo(true) 
        }
        else if(e.target.checked && selectTwo){
            setSelectTwo(false)
        }       
    }
   

    const onClickAvailabilityTwo = (e) =>{        
        if(e.target.checked && selectThree===false){
            setSelectThree(true)
        }
        else if(e.target.checked && selectThree===true){
            setSelectThree(false)
        }
    }

    const onClickAvailabilityThree = (e) =>{        
        if(e.target.checked && selectFour===false){
            setSelectFour(true)
        }
        else if(e.target.checked && selectFour===true){
            setSelectFour(false)
        }
    }

    const [disableAboveGrid, setDisableAboveGrid] = useState(false)
    const [checkedState, setCheckedState] = useState(false)

    useEffect(()=>{        
        let getval = document.getElementById('toggleSwitch')?.value
        
        if(selectTwo===true||selectThree===true || selectFour===true){
            setDisableAboveGrid(true) 
            setGetValue(getValue)//NOSONAR   
            setCheckedState(false)                 
        }
        else if(selectTwo===false||selectThree===false || selectFour===false){
            setDisableAboveGrid(false)
            setGetValue("") 
            setCheckedState(true) 
        }
    },[selectTwo,selectThree, selectFour])

    

    useEffect(()=>{        
        if(disableAboveGrid===true){
            setWoState("")
            setCheckedState(false) 
            setToggleOn(false)            
        }
        else if(disableAboveGrid===false){
            setWoState(assessmentDetailsNew?.classification?.woundClassification)
        }
    },[disableAboveGrid,assessmentDetailsNew])

    // const [errorShowing, setErrorShowing] = useState(false)
    useEffect(()=>{
        if(getValue!=="" || getValue !== undefined){
            setErrorShowing(false)
        }        
    },[getValue,assessmentDetailsNew])

    return (<>
       {
      confirmationModal && <Confirmation
        onClose={() => confirmationClose()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">All progress in this section will be lost. Are you sure want to cancel?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${styles.yes_btn}` }}
              onClick={() => yesConfirmationModalClick()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${styles.no_btn}` }}
              onClick={() => confirmationClose()}
            />
          </div>
        </div>
      </Confirmation>
    }
        <div className={`${styles.mesurement_blk} p-0`}>

            <div>
                {!editable ? <>

                    {/* Edit button  */}
                    {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() =>{
                        setEditable(true);
                        setTimeout(() => {
                            //woundBedIdentifiable
                            if(assessmentDetailsNew?.classification?.woundBedIdentifiable){
                                handleFirstMove(assessmentDetailsNew?.classification?.woundBedIdentifiable === 1 ? 'Yes' : 'No', reset, getValues, dispatcher)
                            }
                            setValue("woundBedIdentifiable",assessmentDetailsNew?.classification?.woundBedIdentifiable === 1 ? 'Yes' : 'No')
                            setValue("woundClassification", assessmentDetailsNew?.classification?.woundClassification?assessmentDetailsNew?.classification?.woundClassification:'');
                            if (assessmentDetailsNew?.classification?.woundClassification === 'Unknown'||assessmentDetailsNew?.classification?.woundClassification === 'unknown') {
                                    // if(document.getElementById('woundClassification')){
                                    //     document.getElementById('woundClassification').value=''
                                    // }
                                    if(getValue){  // New UI Classification
                                        setGetValue('')
                                    }
                            }
                        }, 100)
                    }}>
                        <img src={editIcon} alt="edit" />Edit
                    </div>}

                    {/* Saved data */}
                    {woundClassificationOptions?.isWoundBedIdentifiedQuestionShow ===1 &&  <p>
                        <label>Can the wound bed be identified? </label>
                        <span>
                            {assessmentDetailsNew?.classification?.woundBedIdentifiable === 1 ? 'Yes' : 'No'}
                            {/* {assessmentDetailsNew?.classification?.woundBedIdentifiable === 1 ? 'Yes' : 'No'} */}
                        </span>
                    </p> 
                    }

                    {/* {allNullCheck(assessmentDetailsNew?.classification?.woundClassifiable) ? <p>
                        <label>Unable to classify the wound? *</label>
                        <span>
                            {classifiableOptions && classifiableOptions.length > 0 && classifiableOptions?.map((data) => {
                                if (data.key === assessmentDetailsNew?.classification?.woundClassifiable) {
                                    return data.value
                                }
                            })}
                        </span>
                    </p> : ''} */}

                    {allNullCheck(assessmentDetailsNew?.classification?.woundClassification) ? <p>
                        <label>Wound stage:</label>
                        <span>
                            {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                if (data.key === assessmentDetailsNew?.classification?.woundClassification) {
                                    return data.value
                                }
                            })
                            }
                        </span>
                    </p> : 
                    <p>
                        <label>Wound stage:</label>
                        <span>
                            {'-'}
                        </span>
                    </p>
                    }

                </>

                    :

                    <>

                        {/* Close warning */}
                        {closeWarning && <CloseWarning
                            message="All progress in this section will be lost. Are you sure want to cancel?"
                            yesFn={handleYes}
                            noFn={handleNo}
                        />
                        }
{getUserRole?.role==='ExternalStandardUser'&&ne1getWounAssessmentDetailData?.isClassificationDetailsDone===0?
 <><p>Classification details is not selected for this wound</p></>
 
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[0]['category']==="History/Cause"&&characteristicTabsData[0]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select History/Cause before selecting Classification'}
                                </div>  
 
 </>
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[1]['category']==="Tissue Type"&&characteristicTabsData[1]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select Tissue Type(s) before selecting Classification'}
                                </div>  
 
 </>
 :<>
                        {/* Form field  */}
                        <div className="container-fluid p-0 ftest">
                            <div className={`${styles.patient_content} p-0`}>

                                <Form setFormChangesConfirm={setFormChangesConfirm} formChanges={true} className={'col-12'} onSubmit={handleSubmit(onSubmit)}>

                                    {/* Can wound be identified? */}

                                    {((assessmentDetails?.tissueTypes!==null&&displayFirst) || (assessmentDetails?.tissueTypes!==null&&woundClassificationOptions?.isWoundBedIdentifiedQuestionShow === 1))&& <div className={woundClassificationOptions?.isWoundBedIdentifiedQuestionShow === 1 ? `col-12 ${styles.col_spec}`: `d-none`}>
                                        {woundClassificationOptions?.isWoundBedIdentifiedQuestionShow === 1 && 
                                            <>
                                                <label className={`col-4 col-form-label ${styles.label_spec} pl-3`}>Can the wound bed be identified? <span style={{ color: '#de350b' }}>*</span></label>
                                            </>
                                        }
                                        {woundClassificationOptions?.isWoundBedIdentifiedQuestionShow === 1 && 
                                        <div className={`col-6 p-0`}>
                                            <RadioButton
                                                id="woundBedIdentifiable"
                                                name="woundBedIdentifiable"
                                                classes={{
                                                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                                    inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                                    inputColumn: "custom-control custom-radio custom-control-inline"
                                                }}
                                                radioOption={['Yes', 'No']}
                                                validator={register({ required: "Please select Yes or No" })}
                                                errors={errors}
                                                onChange={handleFirst}
                                                // defaultValue={woundClassificationOptions?.isWoundBedIdentifiedQuestionShow === 1 ? 'Yes' : assessmentDetailsNew?.classification?.woundBedIdentifiable === 0 ? 'No':''}
                                                defaultValue= {assessmentDetailsNew?.classification?.woundBedIdentifiable === 1 ? 'Yes' :assessmentDetailsNew?.classification?.woundBedIdentifiable === 0 ? 'No':''}
                                            />
                                        </div>}
                                    </div>}

                            {/* New Classification UI */} 
                            
                            {/* {assessmentDetails?.tissueTypes===null &&
                                <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>

                                    {ErrorState}
                                </div>                                
                            }   */}

                            
                                    {/* list on cards */}
                                    {assessmentDetails?.historyCause?.causeCategory === 'pi' || assessmentDetails?.historyCause?.causeCategory === 'unabletodetermine' ? 
                                         (showState===true || (woundClassificationOptions?.isWoundBedIdentifiedQuestionShow!==1 && assessmentDetails?.tissueTypes!==null) || assessmentDetailsNew?.classification?.woundBedIdentifiable === 1||assessmentDetailsNew?.classification?.woundBedIdentifiable === 0) &&
                                        <div className={`col-12 pt-1`}>
                                            <div className='row pb-3'>
                                                <div class="col-12 d-flex justify-content-between">
                                                    <label htmlFor="woundClassification" className={`col-form-label ${styles.label_spec}`}>Wound stage: <span style={{ color: '#de350b' }}>*</span></label>
                                                    <div className="text-right custom-control custom-switch pl-0 pt-2">
                                                        <div className={`${styles.toggle_shells_class} ${styles.toggle_shells_class_special} text-right d-flex`}>
                                                            <div className={`${styles.toggle_button_status} pr-5`} style={{fontWeight:"500",fontSize:"17px",}}>Override: </div> 
                                                            <input 
                                                                type="checkbox"
                                                                name="toggle" 
                                                                className="custom-control-input"
                                                                id={"toggleSwitch"}
                                                                data-testid={"toggleSwitch"}                                                   
                                                                onChange={toggleChange} 
                                                                disabled={disableAboveGrid === true && 'true'}
                                                                checked={toggleOn}
                                                            />
                                                            <label className={`custom-control-label ${styles.toggle_switch} ${styles.toggle_shells_class_special}`} for="toggleSwitch"></label> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key !== 'nonremovabledressingdevice' && f.key !== 'unabletodeterminewoundclassification' && f.key !== 'woundcannotbestaged')?.map((data, index) =>(
                                                    
                                                                                                
                                                    <div 
                                                        key={index} 
                                                        id="woundClassification"                                                     
                                                        className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === woState ?  (data.key===keyValue ? (selectOne === true ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} ${styles.selectedSingleFile} pt-4 px-0` : (noBackground ===false ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} ${styles.selectedFile} pt-4 px-0` : `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} pt-4 px-0`)):(noBackground===false ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} ${styles.selectedFile} pt-4 px-0` : `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} pt-4 px-0`)):(data.key===keyValue ? (selectOne === true ? (noBackground===false ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} ${styles.selectedSingleFile} pt-4 px-0`: `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} pt-4 px-0`) : `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} pt-4 px-0`):(disableAboveGrid === false ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.notdisabled} pt-4 px-0`:`col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.disabled} pt-4 px-0`))) : (data.key === woState ? `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.disabled} ${styles.selectedFile} pt-4 px-0` : `col-lg-4 col-md-6 col-sm-12 ${styles.hoverCard} ${styles.disabled} pt-4 px-0`)}
                                                        onClick={(eve)=>handleCategoryChange(eve, data, index)}
                                                        value={data.key}
                                                        >
                                                            
                                                            <span className={`${styles.tooltip}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                            
                                                            <div className={`p-0`}>
                                                                <div className={`${styles.woundImagecard} text-center`}>
                                                                    {PiImage?.map((imgKey)=>{                                                                    
                                                                        if(imgKey?.key === data?.key){
                                                                            return (imgKey?.referenceImage.map(imgtag=>{
                                                                                return (
                                                                                    <img src={imgtag} className={styles.imgCard} alt='woundImage' />
                                                                                )
                                                                            }))
                                                                        }
                                                                        })}
                                                                    </div>   

                                                                {/* <div className={hovercard === false ? `${styles.cardHeading}` : `${styles.cardHeadingHover}`}>
                                                                    <h3>{data.value}</h3>                                                            
                                                                </div> */}
                                                                {subtitlePI.map(res=>{
                                                                    if(res?.key===data?.key){                                                                        
                                                                            if(res?.value!==""){
                                                                                return(
                                                                                    <div className={ `${styles.cardHeadingResVal}`}>
                                                                                        <h3>{data.value}</h3>                                                            
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            else if(res?.value===""){
                                                                                return(
                                                                                    <div className={`${styles.cardHeading}`}>
                                                                                        <h3>{data.value}</h3>                                                            
                                                                                    </div>
                                                                                )                                                                                
                                                                            } 
                                                                        }
                                                                    })
                                                                }
                                                                
                                                                {subtitlePI.map(res=>{
                                                                    if(res?.key===data?.key){
                                                                        return(
                                                                            <div className={res.value !==""? `${styles.cardSubheading}`: `d-none`}>
                                                                                <p>{res.value}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })} 
                                                            </div>
                                                        
                                                    </div>                                                
                                                ))}
                                            </div>

                                            <div className='row pt-4 pb-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'woundcannotbestaged')?.map((data, index) =>(
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectFour === true ? `${styles.cardRadio} col-12 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-12`) : (data.key===keyValue ? (selectFour === true ? `${styles.cardRadio} col-12  ${styles.selectedFileRadio}`:`${styles.cardRadio} col-12`) : `${styles.cardRadio} col-12`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-12` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-12`)}
                                                        // contentEditable = {selectTwo === false ? true : false}
                                                    >   
                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <input id="woundClassification" type="radio" name="imgsel2"  value="" checked={(selectFour === true ? true : false)} onChange={(e)=>handleRadioChangeThree(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityThree(e)} disabled={toggleOn===false && data.enable === 0 && true}/> 
                                                                <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='row pb-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'nonremovabledressingdevice')?.map((data, index) =>(
                                                    
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectTwo === true ? `${styles.cardRadio} col-12 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-12`) : (data.key===keyValue ? (selectTwo===true ? `${styles.cardRadio} col-12  ${styles.selectedFileRadio}`: `${styles.cardRadio} col-12`) : `${styles.cardRadio} col-12`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-12` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-12`)}
                                                        // contentEditable = {selectTwo === false ? true : false}
                                                    >   
                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <input id="woundClassification" type="radio" name="imgsel1" value="" checked={(selectTwo === true ? true : false)} onChange={(e)=>handleRadioChangeOne(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityOne(e)}/> 
                                                                <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                ))}                                                
                                            </div>                              
                                            <div className='row'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'unabletodeterminewoundclassification')?.map((data, index) =>(
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectThree === true ? `${styles.cardRadio} col-12 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-12`) : (data.key===keyValue ? (selectThree === true ? `${styles.cardRadio} col-12  ${styles.selectedFileRadio}`:`${styles.cardRadio} col-12`) : `${styles.cardRadio} col-12`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-12` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-12`)}
                                                        // contentEditable = {selectTwo === false ? true : false}
                                                    >   
                                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            <input id="woundClassification" type="radio" name="imgsel2"  value="" checked={(selectThree === true ? true : false)} onChange={(e)=>handleRadioChangeTwo(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityTwo(e)}/> 
                                                            <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='col-12 pt-4 text-center'>
                                                {errorShowing===true && <span className="text-danger" style={{fontFamily:'open_sansregular',fontSize:'16px'}}>{'Please select the wound stage'}</span>}
                                                {/* {errors?.woundClassification?.message && <span className="text-danger" style={{fontFamily:'open_sansregular',fontSize:'16px'}}>{errors?.woundClassification?.message}</span>} */}
                                            </div>

                                            {hideIt && <div className="mt-4 offset-4">
                                                {noTissueTypes && <div className="text-danger">Review Tissue Types before classifying the wound.</div>}
                                            {/* Save Button */}
                                                <Button
                                                    type="submit"
                                                    value="Save"
                                                    classes={{ className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}` }}
                                                    id="save"
                                                />
                                                {/* cancel Button */}
                                                <Button
                                                    type="button"
                                                    value="Cancel"
                                                    classes={{ className: `btn btn-small ${styles.btn_small_cancel} ${styles.btn_custom} ml-2` }}
                                                    id="cancel"
                                                    onClick={() => {
                                                        window.scrollTo(0, 0)
                                                        setCloseWarning(true)
                                                    }}
                                                />
                                            </div>} 
                                        </div> 
                                        :
                                         assessmentDetails?.tissueTypes !== null &&
                                        <div className={`col-12 pt-2 pl-5 ml-4`}>
                                            <div className='row pb-3'>
                                                <div class="col-10 d-flex justify-content-between pr-3 ml-4">
                                                    <label htmlFor="woundClassification" className={`${styles.label_spec} col-form-label`}>Wound stage: <span style={{ color: '#de350b' }}>*</span></label>
                                                    <div className="text-right custom-control custom-switch pl-0 pt-2">
                                                        <div className={`${styles.toggle_shells_class} text-right d-flex`}>
                                                            <div className={`${styles.toggle_button_status} pr-5`} style={{fontWeight:"500",fontSize:"17px",}}>Override: </div> 
                                                            <input 
                                                                type="checkbox"
                                                                name="toggle" 
                                                                className="custom-control-input"
                                                                id={"toggleSwitch"}
                                                                data-testid={"toggleSwitch"}                                                  
                                                                onChange={toggleChange}                                                                
                                                                disabled={disableAboveGrid === true && 'true'}
                                                                checked={toggleOn}
                                                            />
                                                            <label className={`custom-control-label ${styles.toggle_switch}`} for="toggleSwitch"></label> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row justify-content-start ml-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key !== 'woundcannotbestaged' && f.key !== 'nonremovabledressingdevice' && f.key !== 'unabletodeterminewoundclassification')?.map((data, index) =>(
                                                                                                
                                                    <div 
                                                        key={index} 
                                                        id="woundClassification" 
                                                        className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === woState ? (data.key===keyValue ? (selectOne === true ? `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0` : (noBackground ===false ? `${styles.hoverCardOther} ${styles.notdisabled} ${styles.selectedFile} col-5 col-push-2 pt-4 px-0` : `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0`)) : (noBackground===false ? `${styles.hoverCardOther} ${styles.notdisabled} ${styles.selectedFile} col-5 col-push-2 pt-4 px-0` : `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0`)): (data.key===keyValue ? (selectOne === true ? (noBackground===false ? `${styles.hoverCardOther} ${styles.notdisabled} ${styles.selectedSingleFile} col-5 col-push-2 pt-4 px-0`: `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0`): `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0`) : (disableAboveGrid === false ? `${styles.hoverCardOther} ${styles.notdisabled} col-5 col-push-2 pt-4 px-0`:`${styles.hoverCardOther} ${styles.disabled} col-5 col-push-2 pt-4 px-0`))) : (data.key === woState ? `${styles.hoverCardOther} ${styles.disabled} ${styles.selectedFile} col-5 pt-4 px-0` : `${styles.hoverCardOther} ${styles.disabled} col-5 pt-4 px-0`)}
                                                        onClick={(eve)=>handleCategoryChange(eve, data, index)}
                                                        // contentEditable = {data.enable === 1 || (data.enable === 0 && toggleOn === true) && true}
                                                        // dangerouslySetInnerHTML={{__html: html}}
                                                        value={data.key}
                                                    >
                                                            <span className={`${styles.tooltip}`}>                   
                                                                <img src={infoIcon} alt="info" style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: "visible"}}/>                                                            
                                                                <span class={`${styles.tooltiptextOther}`}>{data.info}</span>                                                            
                                                            </span>

                                                            <div className='p-0'>
                                                                <div className={`${styles.woundImagecard} text-center`}> 
                                                                    {OtherImageFirstRow.map(imgKey=>{
                                                                        if(imgKey.key===data.key){
                                                                            return(
                                                                                imgKey.referenceImage.map(imgtage=>{
                                                                                    return(
                                                                                        <img src={imgtage} className={styles.imgCard} alt='woundImage' />
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>

                                                                <div className={`${styles.woundImagecard} ${styles.woundImagecardExtra} text-center`}> 
                                                                    {OtherImageSecondRow.map(imgKey=>{
                                                                        if(imgKey.key===data.key){
                                                                            return(
                                                                                imgKey.referenceImage.map(imgtage=>{
                                                                                    return(
                                                                                        <img src={imgtage} className={styles.imgCard} alt='woundImage' />
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    })} 
                                                                </div>

                                                                <div className={hovercard === false ? `${styles.cardHeading} pb-3` : `${styles.cardHeadingHover}`}>
                                                                    <h3>{data.value}</h3>                                                            
                                                                </div>
                                                                {/* <div className={hovercard === false ? `${styles.cardSubheading}`: `${styles.cardSubheadingHover}`}>
                                                                    <p>Intact Skin | Pink/Red | Erythema | Warm | Non-Blanchable</p>                                                            
                                                                </div> */}
                                                            </div>
                                                        
                                                    </div>                                                
                                                ))}
                                            </div>                                            

                                            <div className='row pt-4 ml-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'woundcannotbestaged')?.map((data, index) =>(
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectFour === true ? `${styles.cardRadio} col-10 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-10`) : (data.key===keyValue ? (selectFour === true ? `${styles.cardRadio} col-10 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-10`) : `${styles.cardRadio} col-10`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-10` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-10`)}
                                                        // contentEditable = {selectTwo === false ? true : false}
                                                    >   
                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <input id="woundClassification" type="radio" name="imgsel2"  value="" checked={(selectFour === true ? true : false)} onChange={(e)=>handleRadioChangeThree(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityThree(e)} disabled={toggleOn===false && data.enable === 0 && true}/> 
                                                                <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='row pt-4 ml-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'nonremovabledressingdevice')?.map((data, index) =>(                                                        
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectTwo === true ? `${styles.cardRadio} col-10 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-10`) : (data.key===keyValue ? (selectTwo===true ? `${styles.cardRadio} col-10  ${styles.selectedFileRadio}`: `${styles.cardRadio} col-10`) : `${styles.cardRadio} col-10`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-10` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-10`)}>    
                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <input id="woundClassification" type="radio" name="imgsel" value="" checked={(selectTwo === true ? true : false)} onChange={(e)=>handleRadioChangeOne(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityOne(e)}/> 
                                                                <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>                    
                                            <div className='row pt-4 ml-3'>
                                                {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.filter(f=> f.key === 'unabletodeterminewoundclassification')?.map((data, index) =>(
                                                    <div key={index} value={data.key} className={data.enable === 1 || (data.enable === 0 && toggleOn === true) ? (data.key === assessmentDetailsNew?.classification?.woundClassification ? (selectThree === true ? `${styles.cardRadio} col-10 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-10`) : (data.key===keyValue ? (selectThree === true ? `${styles.cardRadio} col-10 ${styles.selectedFileRadio}`:`${styles.cardRadio} col-10`) : `${styles.cardRadio} col-10`)) : (data.key !== woState ? `${styles.cardRadioDisabled} col-10` : `${styles.cardRadioDisabled} ${styles.selectedFileRadio} col-10`)}>    
                                                        <div className='w-100 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <input id="woundClassification" type="radio" name="imgsel" value="" checked={(selectThree === true ? true : false)} onChange={(e)=>handleRadioChangeTwo(e,data,index)}  style={{cursor:'pointer'}} onClick={(e)=>onClickAvailabilityTwo(e)}/> 
                                                                <h3>{data.value}</h3>
                                                            </div>
                                                            <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                                                                <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: data.info !=="" ? "visible" : 'hidden' }}/>                                                            
                                                                <span class={data.info !=="" && `${styles.tooltiptext}`}>{data.info}</span>                                                            
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='col-12 pt-3 text-center'>
                                                {errorShowing===true && <span className="text-danger ml-n5" style={{fontFamily:'open_sansregular',fontSize:'16px'}}>{'Please select the wound stage'}</span>}
                                                {/* {errors?.woundClassification?.message && <span className="text-danger" style={{fontFamily:'open_sansregular',fontSize:'16px'}}>{errors?.woundClassification?.message}</span>} */}
                                            </div>

                                            {(signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt) && <div className="mt-4 offset-4">
                                                {noTissueTypes && <div className="text-danger">Review Tissue Types before classifying the wound.</div>}
                                           
                                                {
                                       isCompleted===0? 
                                       <Button
                                       type="submit"
                                       value="Next"
                                       classes={{ className: `btn btn-small ml-4 ${styles.btn_small} ${styles.btn_custom}` }}
                                       id="save"
                                   />
                                       :<>
                                         {/* Save Button */}
                                         <Button
                                                    type="submit"
                                                    value="Save"
                                                    classes={{ className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}` }}
                                                    id="save"
                                                />
                                                {/* cancel Button */}
                                                <Button
                                                    type="button"
                                                    value="Cancel"
                                                    classes={{ className: `btn btn-small ${styles.btn_small_cancel} ${styles.btn_custom} ml-2` }}
                                                    id="cancel"
                                                    onClick={() => {
                                                        //window.scrollTo(0, 0)
                                                       // setCloseWarning(true)
                                                       setClickCancel(true)
                                                    }}
                                                />
                                       </>}
                                          



                                            </div>}                                            
                                        </div>
                                        
                                        }

                                        {/* <div className='col-12 pt-4 text-center'>
                                            {errorShowing===true && <span className="text-danger" style={{fontFamily:'open_sansregular',fontSize:'16px'}}>{'Please select the wound stagesss'}</span>}
                                        </div> */}
                                        
                                 
                                {/* New Classification UI */}
                                
                                

                                    {/* Button section */}
                                    {/* {(showState===true) || (assessmentDetails?.historyCause?.causeCategory === 'other'&& assessmentDetails?.tissueTypes!==null) && <>
                                        {hideIt && <div className="mt-4 offset-4">
                                            {noTissueTypes && <div className="text-danger">Review Tissue Types before classifying the wound.</div>}
                                           
                                            <Button
                                                type="submit"
                                                value="Save"
                                                classes={{ className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}` }}
                                                id="save"
                                            />
                                            
                                            <Button
                                                type="button"
                                                value="Cancel"
                                                classes={{ className: `btn btn-small ${styles.btn_small_cancel} ${styles.btn_custom} ml-2` }}
                                                id="cancel"
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    setCloseWarning(true)
                                                }}
                                            />
                                        </div>}
                                    </>} */}

                                </Form>

                            </div>
                        </div>
</>
                }
                    </>}
            </div>
        </div>
    </>)
}
