import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual } from "react-redux";
import { setHistoryCauseFormData } from "../../../app/patientSlice";
import { getHSDataHelper, signedLockedAccess } from "../../../utils";

import {
  Button,
  FancyDropDown,
  Form,
  RadioButton,
  SelectBox,
  TextField,
} from "../../primary";
import { Accordion } from "../../secondary/Accordion/accordion";
export function CharacteristicHistoryEdit({
  poa,
  pi,
  styles,
  save,
  setCategory,
  setSelectedCause,
  dispatch,
  assessmentDetails,
  piHistory,
  woundAge,
  photoType,
  documentProvidedBy,
  historyCause,
  handleNext,
  handleSave,
  setCloseWarning,
  formPoa,
  setFormPoa,
  historyCauseFormData,
  setPi,
  hideIt,
  setOtherDocType,
  otherDocType,
  otherDocTypeVal,
  viewDocTypeSelect,
  setViewDocTypeSelect,
  selectedCause,
  category,
  errChkHistCause,
  formChangesConfirm,
  setFormChangesConfirm,
  setClickCancel
}) {

  // Setting default value for condition POA (from 1 and 0 to Yes and No)
  const tempAPIData = { ...assessmentDetails?.historyCause };
  if (tempAPIData.newWound === 1) {
    tempAPIData["conditionPOA"] = tempAPIData.conditionPOA === 1 ? "Yes" : "No";
  }

  useEffect(() => {
    if (tempAPIData.conditionPOA === "Yes") {
      setFormPoa(true);
    }
  }, []);
  /* Getting temp values if there are any */
  /* Since we are navigating between history/cause and historical view, 
       we need to store the data selected in history/cause locally,
       we are using 'watch' functionality, storing the selected data in redux, if there
       are data, setting it as default value in 'useForm' */
  /*const { register, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: historyCauseFormData?.cause
      ? historyCauseFormData
      : tempAPIData,
  });*/
  const { register, handleSubmit, reset, watch, errors } = useForm({});
  /* Watching the fields */
  const fields = watch([
    "cause",
    "woundAge",
    "conditionPOA",
    "photoType",
    "piHistory",
  ]);
 
const [piHistoryDyn, setPiHistory] = useState(assessmentDetails?.historyCause?.piHistory)
const [woundAgeDyn, setWoundAge] = useState(assessmentDetails?.historyCause?.woundAge)
const [photoTypeDyn, setPhotoType] = useState(assessmentDetails?.historyCause?.photoType)
const [documentProvidedByDyn, setDocumentProvidedBy] = useState(assessmentDetails?.historyCause?.documentProvidedBy)
const [conditionPOADyn, setConditionPOA] = useState(assessmentDetails?.historyCause?.conditionPOA)
  useEffect(() => {
    /*if (!shallowEqual(fields, historyCauseFormData)) {
      dispatch(setHistoryCauseFormData(fields));
    }*/
    setTimeout(() => {
      if (
        document.getElementById("documentProvidedBy") &&
        !Array.isArray(viewDocTypeSelect) &&
        viewDocTypeSelect
      ) {
        document.getElementById("documentProvidedBy").value = viewDocTypeSelect;
      }

    }, 500);
  }, [fields, historyCauseFormData, dispatch]);

  const getHSData = (cat, value) => {
    getHSDataHelper(cat, value, setPi, setSelectedCause, setCategory);
  };
  const documentProvidedByChange = (val) => {
    setOtherDocType(false);
    setViewDocTypeSelect(val?val.replace(/\s/g, '').toLowerCase():'');
    if (val !== "" && val.replace(/\s/g, '').toLowerCase() === "other") {
      setOtherDocType(true);
    }
  };
  useEffect(() => {
    if (
      assessmentDetails?.historyCause?.cause &&
      assessmentDetails?.historyCause?.causeCategory
    ) {
      getHSDataHelper(
        assessmentDetails?.historyCause?.causeCategory,
        assessmentDetails?.historyCause?.cause,
        setPi,
        setSelectedCause,
        setCategory
      );
    }
  }, [assessmentDetails]);


  /**** */
  return (
    <div>
      {/* Editable field */}
      <div className="container-fluid p-0">
        <div className={`${styles.patient_content} p-0`}>
          <Form
            setFormChangesConfirm={setFormChangesConfirm}
            formChanges={true}
            className="col-12 pl-1 pr-1"
            onSubmit={handleSubmit((data) => handleSave(data, reset))}
          
          >
            {/* History cause */}
            {/* Label */}
            <label
              htmlFor="cause"
              className={`col-4 pl-0 col-form-label ${styles.label_spec}`}
            >
              History/Cause <span style={{ color: "#de350b" }}>*</span>
            </label>
            <div
              className={`col-12 ${styles.col_spec} ${styles.history_cause_fix}`}
            >
              <div className={"col-12 p-1"}>
                {errChkHistCause && (
                  <span className={`mt-n3 ${styles.hist_cause_err}`}>
                    Please select the history/cause of the wound from the list
                    or name it something else
                  </span>
                )}
                <FancyDropDown
                  id={"cause"}
                  name={"cause"}
                  classes={{
                    column: `col-12 ${styles.col_spec} mb-2`,
                    inputClassName: styles.basic_select,
                    inputColumn: `p-1 pr-2 mt-4`,
                  }}
                  listOptions={historyCause}
                  defaultValue={
                    historyCauseFormData?.cause &&
                    !Array.isArray(historyCauseFormData?.cause)
                      ? historyCauseFormData
                      : tempAPIData
                  }
                  getData={getHSData}
                  validator={register}
                  showCom={true}
                />
              </div>
            </div>

            {!poa && (
              <>
                {/* Condition of POA radio         */}
                {errors && (
                  <span className={`mt-n3 ml-2 ${styles.hist_cause_err}`}>
                    {(errors['conditionPOA']) ? errors['conditionPOA'].message:''}
                  </span>
                )}
                <Accordion
                 histCause={true}
                  defaultShow={1}
                  classes={{
                    accBodyCust: `${styles.acc_body_cust} mb-1`,
                    accHeadCust: `${styles.acc_head_cust}`,
                    accBtnCust: `${styles.acc_btn_cust}`,
                  }}
                  idControl={2}
                  heading={
                    <div>
                      {
                        <>
                          <b>
                            Condition present on admission?{" "}
                            <span
                              style={{ color: "#de350b", fontSize: "31px" }}
                            >
                              *
                            </span>
                          </b>
                        </>
                      }
                    </div>
                  }
                  content={
                    <div>
                      <RadioButton
                        id="conditionPOA"
                        name="conditionPOA"
                        classes={{
                          column: `ml-4 mt-n0 mb-2`,
                          labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                          inputClassName: `custom-control-input ${styles.custom_control_input}`,
                          inputColumn:
                            "custom-control custom-radio custom-control-inline",
                        }}
                        radioOption={["Yes", "No"]}
                        validator={register({
                          required: "Please select Yes or No",
                        })}
                       defaultValue={conditionPOADyn===1?'Yes':conditionPOADyn===0?'No':''}
                      />
                   
                    </div>
                  }
                />
                  
              </>
            )}

            {/* For pressure injury fields  */}
            {/* Has PI */}
            {pi && save && (
              <>

                {/* PI history */}
                <span className={`mt-n3 ml-2 ${styles.hist_cause_err}`}>
                    {(errors['piHistory']) ? errors['piHistory'].message:''}
                  </span>
                <Accordion
                   histCause={true}
                   defaultShow={1}
                  classes={{
                    accBodyCust: `${styles.acc_body_cust}`,
                    accHeadCust: `${styles.acc_head_cust}`,
                    accBtnCust: `${styles.acc_btn_cust}`,
                  }}
                  idControl={4}
                  heading={
                    <div>
                      {
                        <>
                          <b>
                            Does the patient has a past history of Pressure
                            Injury/Ulcer for the same wound?{" "}
                            <span
                              style={{ color: "#de350b", fontSize: "31px" }}
                            >
                              *
                            </span>
                          </b>
                        </>
                      }
                    </div>
                  }
                  content={
                    <div>

{
                   Array.isArray(piHistory) && piHistory.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                    id={optionVal?.key}
                                    name="piHistory"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register({
                                        required: "Please select an option",
                                      })}
                                     // errors={errors}
                                 defaultValue={piHistoryDyn&&piHistoryDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                />
                      </div>

</div>
                    }) 
                  }
                  
                    </div>
                  }
                />
               
              </>
            )}

            {/* Wound age dropdown */}
            <Accordion
              defaultShow={0}
              classes={{
                accBodyCust: `${styles.acc_body_cust}`,
                accHeadCust: `${styles.acc_head_cust}`,
                accBtnCust: `${styles.acc_btn_cust}`,
              }}
              idControl={1}
              heading={
                <div>
                  {
                    <>
                      <b>
                        Wound age{" "}
                        <span
                          style={{ color: "#de350b", fontSize: "31px" }}
                        ></span>
                      </b>
                    </>
                  }
                </div>
              }
              content={
                <div>
                  {
                   Array.isArray(woundAge) && woundAge.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         
                         <RadioButton
                                     id={optionVal?.key}
                                    name="woundAge"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                    //onChange={(e)=>woundAgeSel(e,'woundAge')}
                                    defaultValue={woundAgeDyn&&woundAgeDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                    unCheck={1}

                                />
                      </div>

</div>
                    }) 
                  }
                </div>
              }
            />
            {/* Below fields only for non-POA */}

            {!poa && (
              <>
                {formPoa && (
                  <>
                    <Accordion
                      defaultShow={0}
                      classes={{
                        accBodyCust: `${styles.acc_body_cust}`,
                        accHeadCust: `${styles.acc_head_cust}`,
                        accBtnCust: `${styles.acc_btn_cust}`,
                      }}
                      idControl={2}
                      heading={
                        <div>
                          {
                            <>
                              <b>
                                Photo type{" "}
                                <span
                                  style={{ color: "#de350b", fontSize: "31px" }}
                                ></span>
                              </b>
                            </>
                          }
                        </div>
                      }
                      content={
                        <div>

{
                   Array.isArray(photoType) && photoType.map((optionVal, optionIndex) => {
                   let selPhotoType=''
                    if(assessmentDetails?.historyCause?.photoType){
                           if(optionVal.key===assessmentDetails?.historyCause?.photoType){
                               selPhotoType=optionVal.value
                           }
                    }
                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                     id={optionVal?.key}
                                    name="photoType"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                    defaultValue={selPhotoType?selPhotoType:''}
                                    //defaultValue={photoTypeDyn&&photoTypeDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                    unCheck={1}
                                />
                      </div>

</div>
                    }) 
                  }
                        </div>
                      }
                    />

                    {/* <SelectBox
                                    id="documentProvidedBy"
                                    name="documentProvidedBy"
                                    label={`Documentation provided by:`}
                                    classes={{
                                        column: `col-12 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-8 p-0 ${styles.select_wrapper}`
                                    }}
                                    optionValue={documentProvidedBy}
                                    validator={register}
                                    placeholder="Select"
                                    onChange={(e) => documentProvidedByChange(e.target.value)}
                                    defaultValue={viewDocTypeSelect}
                                />
                                {otherDocType&&
                                <>
                                    <TextField
                                    id='documentProvidedByOther'
                                    name='documentProvidedByOther'
                                    label={``}
                                    classes={{
                                        column: `col-12 ml-2 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-8 p-0 offset-md-4',
                                        inputClassName: `${styles.basic_input}`
                                    }}
                                    type="text"
                                    validator={register}
                                    defaultValue={otherDocTypeVal&&otherDocTypeVal!=='other'?otherDocTypeVal:''}
                                />
                                </>} */}
                    <Accordion
                      defaultShow={0}
                      classes={{
                        accBodyCust: `${styles.acc_body_cust}`,
                        accHeadCust: `${styles.acc_head_cust}`,
                        accBtnCust: `${styles.acc_btn_cust}`,
                      }}
                      idControl={3}
                      heading={
                        <div>
                          {
                            <>
                              <b>
                                Documentation type{" "}
                                <span
                                  style={{ color: "#de350b", fontSize: "31px" }}
                                ></span>
                              </b>
                            </>
                          }
                        </div>
                      }
                      content={
                        <div>
                          {
                   Array.isArray(documentProvidedBy) && documentProvidedBy.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                     id={optionVal?.key}
                                    name="documentProvidedBy"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                    onChange={(e) =>
                                      documentProvidedByChange(e.target.value)
                                    }
                                   // defaultValue={viewDocTypeSelect}
                                    defaultValue={viewDocTypeSelect&&viewDocTypeSelect===optionVal?.key?optionVal?.value:''}
                                    unCheck={1}
                                />
                      </div>

</div>
                    }) 
                  }
                          {otherDocType && (
                            <>
                              <TextField
                                id="documentProvidedByOther"
                                name="documentProvidedByOther"
                                label={``}
                                classes={{
                                  column: `ml-4 ${styles.col_spec}`,
                                  labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                  inputColumn: "col-11 p-0",
                                  inputClassName: `${styles.basic_input}`,
                                }}
                                type="text"
                                validator={register}
                                defaultValue={
                                  otherDocTypeVal && otherDocTypeVal.replace(/\s/g, '').toLowerCase() !== "other"
                                    ? otherDocTypeVal
                                    : ""
                                }
                              />
                            </>
                          )}
                        </div>
                      }
                    />
                  </>
                )}
              </>
            )}

            {/* Button section */}
            {(signedLockedAccess(assessmentDetails?.assessmentStage)) && hideIt && (
              <div className="col-12 p-0  mt-5">
                <div className="offset-4">
                  {/* Show next button if we haven't went to 'Historical view screen  */}
                    <>
                      <Button
                        type="submit"
                        value="Save"
                        classes={{
                          className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}`,
                        }}
                        id="save"
                      />
                      <Button
                        type="button"
                        value="Cancel"
                        classes={{
                          className: `btn btn-small ml-2 ${styles.btn_small_cancel} ${styles.btn_custom}`,
                        }}
                        id="cancel"
                        onClick={() => {
                          //window.scrollTo(0, 0);
                          setClickCancel(true);
                          //setCloseWarning(true);
                        }}
                      />
                    </>
                 
                </div>
                <br />
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
